.toast {
  opacity: 0.8 !important;
}

.toast.toast-success {
  background-color: #1bc5bd !important;
  color: #ffffff !important;
}

.toast.toast-info {
  background-color: #8950fc !important;
  color: #ffffff !important;
}

.toast.toast-warning {
  background-color: #ffa800 !important;
  color: #ffffff !important;
}

.toast.toast-error {
  background-color: #f64e60 !important;
  color: #ffffff !important;
}

.StripeElement {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #464e5f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.StripeElement--invalid {
  border-color: #f64e60 !important;
}

.dropdown-content {
  z-index : 9999!important;
  background-color: #ffffff!important;
}